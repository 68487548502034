<template>
        <video ref="videoPlayer" class="video-js is-parent-full-width-height"></video>
</template>
<style lang="scss" scoped>
.is-parent-full-width-height {
    width: 100%;
    height: 100%;
}
</style>
<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
    name: 'BasePlayer',
    props: {
        options: {
            type: Object,
            default () {
                return {}
            }
        },
        videoSource: {
            type: String
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options)
        this.player.src({
            src: this.videoSource,
            type: 'application/x-mpegURL'
        })
        this.player.play()
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>